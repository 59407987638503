/*-----------------------------------------------------------------------------------

 Theme Name: Wavo
 Theme URI: http://
 Description: The Multi-Purpose Onepage Template
 Author: ui-themez
 Author URI: http://themeforest.net/user/ui-themez
 Version: 1.0

 Main Color   : #24d5b4
 main Font    : Poppins , Roboto

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------

== Table Of Content

	01 Basics
	02 Helper Classes
	03 Preloader
	04 Buttons
	05 Cursor
	06 progress wrap
	07 Text Animation & Images
    08 Menu & Navbar
	09 Slider
	10 About
	11 Work
	12 Process
	13 Testimonials
	14 Agency
    15 min-area
    16 Brands
	17 Team
	18 Blog
	19 Contact
	20 call-action
    21 Fotter
    22 Dark-Theme
	23 Responsive


---------------------------------------------------------------- */
/* ====================== [ 01 Start Basics ] ====================== */

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  list-style: none;
  word-wrap: break-word;
}

html,
section {
  color: #000;
  line-height: 1.3;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

.main-content {
  background: #fff;
  position: relative;
  margin-top: 75vh;
  z-index: 3;
}

p {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 400;
  color: #888;
  line-height: 2;
  margin: 0;
}

img {
  width: 100%;
  height: auto;
}

span,
a,
a:hover {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

/* ====================== [ End Basics ] ====================== */
/* ====================== [ 02 Start Helper Classes ] ====================== */
.section-padding {
  padding: 120px 0;
}

.ubuntu {
  font-family: 'Ubuntu', sans-serif;
}

.nmb-font {
  color: white;
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
}

.sub-title {
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 15px;
  padding-left: 70px;
  position: relative;
}

.sub-title:after {
  content: '';
  width: 60px;
  height: 1px;
  background: #212529;
  position: absolute;
  left: 0;
  bottom: 45%;
}

.extra-title {
  font-weight: 800;
  font-size: 38px;
  line-height: 1.4;
}

.extra-text {
  font-size: 20px;
  color: #1e1e1e;
}

.kmore {
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-top: 30px;
  padding-left: 70px;
  position: relative;
}

.kmore:after {
  content: '';
  width: 60px;
  height: 1px;
  background: #777;
  position: absolute;
  left: 0;
  bottom: 45%;
}

.section-head {
  margin-bottom: 80px;
}

.section-head h6 {
  font-size: 13px;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: white;
  margin-bottom: 10px;
}

.section-head h3 {
  font-weight: 700;
  line-height: 1.5;
}

.bg-gray {
  background: #fafafa;
}

.bg-pattern {
  position: relative;
  background-size: 20% !important;
  z-index: 2;
}

.bg-pattern:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fafafa;
  opacity: .8;
  z-index: -1;
}

.o-hidden {
  overflow: hidden;
}

.position-re {
  position: relative;
}

.full-width {
  width: 100% !important;
  visibility: visible;
}

.bg-img {
  background-size: cover;
  background-position: 0 -150px;
  background-repeat: no-repeat;
}

.no-cover {
  background-repeat: repeat;
  background-size: auto;
}

.bg-fixed {
  background-attachment: fixed;
}

.on-top {
  position: relative;
  z-index: 7;
}

.valign {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/* ====================== [ End Helper Classes ] ====================== */
/* ====================== [ 03 Start Preloader ] ====================== */
.pace {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99999999999999;
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  border: 0px;
  height: 1px;
  overflow: hidden;
  background: #ccc;
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.pace .pace-progress {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  max-width: 400px;
  position: fixed;
  z-index: 99999999999999;
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  height: 100%;
  width: 100%;
  background: #212529;
}

.pace.pace-inactive {
  width: 100vw;
  opacity: 0;
}

.pace.pace-inactive .pace-progress {
  max-width: 100vw;
}

#preloader {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  z-index: 9999999;
}

#preloader:after,
#preloader:before {
  content: '';
  position: fixed;
  left: 0;
  height: 50vh;
  width: 100%;
  background: #f1f1f1;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

#preloader:before {
  top: 0;
}

#preloader:after {
  bottom: 0;
}

#preloader.isdone {
  visibility: hidden;
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

#preloader.isdone:after,
#preloader.isdone:before {
  height: 0;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.55, 1);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.loading-text {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 10px;
  position: absolute;
  top: 45%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-text.isdone {
  top: 40%;
  opacity: 0;
  -webkit-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

/* ====================== [ End Preloader ] ====================== */
/* ====================== [ 04 Start Button Style ] ====================== */
.btn-curve {
  background: transparent;
  padding: 14px 35px;
  border: 1px solid #1e1e1e;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.btn-curve:hover:after {
  bottom: -10px;
  border-radius: 0;
}

.btn-curve:hover span {
  color: #fff;
}

.btn-curve:after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: 100%;
  background: #1e1e1e;
  border-radius: 0 0 100% 100%/0 0 100% 100%;
  -webkit-transition: all 0.6s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.6s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.6s cubic-bezier(1, 0, 0.55, 1);
}

.btn-curve span {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  z-index: 2;
  -webkit-transition: all .8s;
  -o-transition: all .8s;
  transition: all .8s;
}

.btn-curve.btn-lit {
  border-color: #fff;
  color: #fff;
}

.btn-curve.btn-lit:hover span {
  color: #1e1e1e;
}

.btn-curve.btn-lit:after {
  background: #fff;
}

.btn-curve.btn-blc {
  background: #1e1e1e;
}

.btn-curve.btn-blc:after {
  background: #fff;
}

.btn-curve.btn-blc span {
  color: #fff;
}

.btn-curve.btn-blc:hover span {
  color: #1e1e1e;
}

.btn-curve.btn-lg {
  padding: 14px 40px;
}

.btn-curve.btn-lg span {
  font-size: 14px;
}

.btn-curve.btn-wit {
  background: #fff;
  border-color: #fff;
}

.btn-curve.btn-wit:after {
  background: #090909;
}

.btn-curve.btn-wit span {
  color: #1e1e1e;
}

.btn-curve.btn-wit:hover span {
  color: #fff;
}

.btn-curve.btn-radius {
  border-radius: 30px;
}

/* ====================== [ End Button Style ] ====================== */
/* ====================== [ 05 Start Cursor Style ] ====================== */
/*.cursor {
  width: 15px;
  height: 15px;
  background-color: #ccc;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  left: -5px;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
  Pointer-events: none;
  z-index: 9999999999;
  opacity: .8;
}

.cursor2 {
  display: none;
}

/* ====================== [ End Cursor Style ] ====================== */
/* ====================== [ 06 Start progress-wrap ] ====================== */
.progress-wrap {
  position: fixed;
  bottom: 30px;
  right: 30px;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.2);
  box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.2);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.progress-wrap::after {
  position: absolute;
  font-family: 'Font Awesome 5 Free';
  content: '\f077';
  text-align: center;
  line-height: 44px;
  font-size: 13px;
  font-weight: 900;
  color: #6c6d6d;
  left: 0;
  top: 0;
  height: 44px;
  width: 44px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap svg path {
  fill: none;
}

.progress-wrap svg.progress-circle path {
  stroke: #6c6d6d;
  stroke-width: 4;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 400ms linear;
  -o-transition: all 400ms linear;
  transition: all 400ms linear;
}

.progress-wrap {
  -webkit-box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.2);
  box-shadow: inset 0 0 0 2px rgba(130, 130, 130, 0.2);
}

.progress-wrap::after {
  color: #6c6d6d;
}

.progress-wrap svg.progress-circle path {
  stroke: #6c6d6d;
}

/* ====================== [ End progress-wrap ] ====================== */
/* ====================== [ 07 Start Text Animation & Images ] ====================== */
.splitting.animated .char {
  -webkit-animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.splitting .whitespace {
  width: 10px;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.splitting.txt.animated .char {
  -webkit-animation: fadeIn 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeIn 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(10ms * var(--char-index));
  animation-delay: calc(10ms * var(--char-index));
}

.splitting.txt .whitespace {
  width: 5px;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.imgio {
  position: relative;
}

.imgio .cimgio {
  position: absolute;
  height: 100%;
  width: 100%;
  bottom: 0;
  right: 0;
  background: #fff;
  visibility: hidden;
  -webkit-transition: all .5s linear;
  -o-transition: all .5s linear;
  transition: all .5s linear;
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.imgio .animated {
  height: 0;
}

.cimgio[data-delay="100"] {
  -webkit-transition-delay: .1s;
  -o-transition-delay: .1s;
  transition-delay: .1s;
}

.cimgio[data-delay="200"] {
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.cimgio[data-delay="300"] {
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.cimgio[data-delay="400"] {
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.cimgio[data-delay="500"] {
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.cimgio[data-delay="600"] {
  -webkit-transition-delay: .6s;
  -o-transition-delay: .6s;
  transition-delay: .6s;
}

.cimgio[data-delay="700"] {
  -webkit-transition-delay: .7s;
  -o-transition-delay: .7s;
  transition-delay: .7s;
}

.cimgio[data-delay="800"] {
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.cimgio[data-delay="900"] {
  -webkit-transition-delay: .9s;
  -o-transition-delay: .9s;
  transition-delay: .9s;
}

.cimgio[data-delay="1s"] {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

/* ====================== [ End Text Animation & Images ] ====================== */
/* ====================== [ 08 Start Menu & Navbar ] ====================== */
.topnav {
  position: fixed;
  top: 0;
  left: 100px;
  width: 100%;
  z-index: 999999;
  color: #fff;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
}

.topnav .container,
.topnav .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topnav .container-fluid {
  padding: 0 50px;
}

/*.topnav .logo {
  width: 75px;
}*/

.topnav .menu-icon {
  margin-left: auto;
  cursor: pointer;
}

.topnav .menu-icon:hover .text .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.topnav .menu-icon .text {
  font-size: 13px;
  font-weight: 500;
  padding-left: 15px;
  display: table-cell;
  vertical-align: middle;
  position: relative;
}

.topnav .menu-icon .text:after {
  content: 'close';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-left: 15px;
  opacity: 0;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.topnav .menu-icon .text .word {
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
  -webkit-transition-delay: .6s;
  -o-transition-delay: .6s;
  transition-delay: .6s;
}

.topnav .menu-icon .text.open .word {
  opacity: 0;
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.topnav .menu-icon .text.open:after {
  opacity: 1;
  -webkit-transition-delay: .6s;
  -o-transition-delay: .6s;
  transition-delay: .6s;
}

.topnav .menu-icon .icon {
  display: table-cell;
}

.topnav .menu-icon .icon i {
  display: block;
  width: 20px;
  height: 3px;
  background: #fff;
  margin: 4px 0;
}

.topnav.dark .menu-icon {
  color: #000;
}

.topnav.dark .menu-icon .icon i {
  background: #000;
}

.topnav.dark.navlit .menu-icon {
  color: #fff;
}

.topnav.dark.navlit .menu-icon .icon i {
  background: #fff;
}

.nav-scroll {
  background: #111;
}

.hamenu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background: #0a0a0a;
  padding: 120px 30px 30px;
  overflow: hidden;
  z-index: 9999;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0.55, 1);
}

.hamenu.open .menu-links .main-menu>li .link {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu.open .cont-info:after {
  height: 100vh;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu.open .cont-info .item {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu .menu-links {
  color: #fff;
  margin-top: 30px;
}

.hamenu .menu-links .o-hidden {
  display: inline-block;
}

.hamenu .menu-links .main-menu {
  position: relative;
  z-index: 2;
}

.hamenu .menu-links .main-menu.gosub>li {
  opacity: 0;
  visibility: hidden;
  -webkit-transition-delay: .2s !important;
  -o-transition-delay: .2s !important;
  transition-delay: .2s !important;
}

.hamenu .menu-links .main-menu.gosub>li .link {
  -webkit-transform: translateY(45px) !important;
  -ms-transform: translateY(45px) !important;
  transform: translateY(45px) !important;
  opacity: 0 !important;
  visibility: hidden !important;
  -webkit-transition-delay: .2s !important;
  -o-transition-delay: .2s !important;
  transition-delay: .2s !important;
}

.hamenu .menu-links .main-menu.gosub .sub-menu.sub-open {
  z-index: 3 !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.hamenu .menu-links .main-menu>li {
  font-size: 30px;
  font-weight: 600;
  padding: 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  opacity: 1;
  visibility: visible;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.hamenu .menu-links .main-menu>li span.nm {
  opacity: .8;
  font-size: 11px;
  margin-right: 10px;
}

.hamenu .menu-links .main-menu>li .link {
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  cursor: pointer;
}

.hamenu .menu-links .main-menu>li .link i {
  font-size: 15px;
  margin-left: 5px;
}

.hamenu .menu-links .main-menu .sub-menu {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.hamenu .menu-links .main-menu .sub-menu.sub-open {
  z-index: 3;
}

.hamenu .menu-links .main-menu .sub-menu.sub-open li .sub-link {
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}

.hamenu .menu-links .main-menu .sub-menu li {
  color: #eee;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 8px 10px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.hamenu .menu-links .main-menu .sub-menu li:hover {
  color: #fff;
}

.hamenu .menu-links .main-menu .sub-menu li .sub-link {
  -webkit-transform: translateY(45px);
  -ms-transform: translateY(45px);
  transform: translateY(45px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
}

.hamenu .menu-links .main-menu .sub-menu li .sub-link.back {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

.hamenu .menu-links .main-menu .sub-menu li .sub-link.back i {
  margin-left: 10px;
}

.hamenu .menu-links .main-menu .sub-menu li .sub-link em {
  font-size: 13px;
  font-weight: 300;
  margin-left: 10px;
}

.hamenu .cont-info {
  color: #fff;
  margin-top: 50px;
  position: relative;
}

.hamenu .cont-info:after {
  content: '';
  width: 1px;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: -40px;
  top: -170px;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
}

.hamenu .cont-info .item {
  margin-bottom: 30px;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  opacity: 0;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
}

.hamenu .cont-info .item:last-of-type {
  margin-bottom: 0;
}

.hamenu .cont-info .item h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 10px;
}

/* ====================== [ End Menu & Navbar ] ====================== */
/* ====================== [ 09 Start Slider ] ====================== */
.slider.fixed-slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.slider .parallax-slider {
  position: relative;
}

.slider .parallax-slider .swiper-slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 70vh;
  padding-bottom: 50px;
}

.slider .parallax-slider .swiper-slide .bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}

.slider .parallax-slider .swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}

.slider .parallax-slider .swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}

.slider .parallax-slider .swiper-slide-active .caption h1,
.slider .parallax-slider .swiper-slide-active .caption .dis {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.slider .parallax-slider .swiper-slide-active .caption .dis {
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.slider .parallax-slider .swiper-slide-active .caption.clasc .btn-curve,
.slider .parallax-slider .swiper-slide-active .caption.clasc p {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.slider .parallax-slider .caption h1 {
  font-size: 80px;
  font-weight: 700;
  letter-spacing: 2px;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .5s,
    visibility .5s;
  -o-transition: opacity .5s,
    visibility .5s;
  transition: opacity .5s,
    visibility .5s;
}

.slider .parallax-slider .caption h1 .stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.slider .parallax-slider .caption h1 .stroke span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.slider .parallax-slider .caption .dis {
  margin-top: 30px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  position: relative;
  padding-left: 80px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition-delay: .2s;
  -o-transition-delay: .2s;
  transition-delay: .2s;
}

.slider .parallax-slider .caption .dis:after {
  content: '';
  width: 60px;
  height: 1px;
  background: #eee;
  position: absolute;
  left: 0;
  top: 50%;
}

.slider .parallax-slider .caption .dis:hover .char {
  -webkit-animation: fadeIn 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeIn 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.slider .parallax-slider .caption.clasc h1 {
  font-size: 55px;
  font-weight: 600;
}

.slider .parallax-slider .caption.clasc p {
  color: #eee;
  margin-top: 20px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  -webkit-transition-delay: .4s;
  -o-transition-delay: .4s;
  transition-delay: .4s;
}

.slider .parallax-slider .caption.clasc .btn-curve {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  -webkit-transition-delay: .7s;
  -o-transition-delay: .7s;
  transition-delay: .7s;
}

.slider .parallax-slider .social {
  position: absolute;
  left: 50px;
  bottom: 20px;
  z-index: 6;
}

.slider .parallax-slider .social.active a {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.slider .parallax-slider .social .icon {
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
}

.slider .parallax-slider .social a {
  color: #fff;
  font-size: 11px;
  margin-right: 7px;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.center-slider {
  text-align: center;
}

.center-slider .parallax-slider .caption h1 {
  font-size: 85px;
  font-weight: 800;
  letter-spacing: 4px;
}

.slide-full .swiper-slide {
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 50px;
}

.slide-full .swiper-slide .bg-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
}

.slide-full .capt {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 3;
}

.slide-full .capt h1 {
  font-size: 5vw;
  font-weight: 600;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .5s,
    visibility .5s;
  -o-transition: opacity .5s,
    visibility .5s;
  transition: opacity .5s,
    visibility .5s;
}

.slide-full .capt h1 .stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.slide-full .capt h1 .stroke span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.slide-full .capt .dis {
  margin-top: 30px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  position: relative;
  padding-left: 80px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}

.slide-full .capt .dis:after {
  content: '';
  width: 60px;
  height: 1px;
  background: #eee;
  position: absolute;
  left: 0;
  top: 50%;
}

.slide-full .capt .dis:hover .char {
  -webkit-animation: fadeIn 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeIn 0.4s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(30ms * var(--char-index));
  animation-delay: calc(30ms * var(--char-index));
}

.slide-full .social {
  position: absolute;
  left: 50px;
  bottom: 20px;
  z-index: 6;
}

.slide-full .social.active a {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.slide-full .social .icon {
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
}

.slide-full .social a {
  color: #fff;
  font-size: 11px;
  margin-right: 7px;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.slide-full .swiper-slide-active .capt h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}

.slide-full .swiper-slide-active .capt h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}

.slide-full .swiper-slide-active .capt h1,
.slide-full .swiper-slide-active .capt .dis {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}

.slide-full .swiper-slide-active .capt .dis {
  -webkit-transition-delay: .8s;
  -o-transition-delay: .8s;
  transition-delay: .8s;
}

.page-header {
  background: #090909;
  color: #fff;
}

.page-header .cont {
  padding: 220px 0 120px;
}

.page-header .cont h4 {
  line-height: 1.6;
}

.page-header .cont h4 .stroke {
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.7);
  color: transparent;
}

.page-header .img-wrapper {
  position: relative;
  min-height: 70vh;
}

.page-header .img-wrapper .title {
  position: absolute;
  top: 0;
  right: 0px;
  width: 100%;
  text-align: right;
  -webkit-transform: translateY(-55%);
  -ms-transform: translateY(-55%);
  transform: translateY(-55%);
  z-index: 3;
}

.page-header .img-wrapper .title h3 {
  font-size: 100px;
  font-weight: 900;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.page-header .img-wrapper .map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.page-header.proj-det .img-wrapper {
  min-height: 80vh;
}

.page-header.blg .cont {
  padding: 220px 0;
}

.page-header.blg .cont h2 {
  line-height: 1.6;
  margin-bottom: 10px;
}

.page-header.blg .cont .info a {
  margin: 0 10px;
  font-size: 13px;
  font-family: 'Roboto Mono', monospace;
  color: #aaa;
}

.slide-controls .swiper-pagination-fraction {
  position: absolute;
  right: 50px;
  left: auto;
  bottom: 20px;
  top: auto;
  width: auto;
  color: #fff;
}

.slide-controls .swiper-pagination-fraction .swiper-pagination-current {
  font-size: 20px;
  position: relative;
}

.slide-controls .swiper-pagination-fraction .swiper-pagination-current:after {
  content: '/';
  position: relative;
  margin: 0 10px;
  font-size: 12px;
}

.slide-controls .swiper-button-next,
.slide-controls .swiper-button-prev {
  position: absolute;
  width: 48px;
  height: 48px;
  bottom: auto;
  top: 50%;
  left: auto;
  right: 50px;
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  font-size: 14px;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
}

.slide-controls .swiper-button-next:after,
.slide-controls .swiper-button-prev:after {
  display: none;
}

.slide-controls .swiper-button-next:hover,
.slide-controls .swiper-button-prev:hover {
  background: rgba(255, 255, 255, 0.1);
}

.slide-controls .swiper-button-next i,
.slide-controls .swiper-button-prev i {
  position: absolute;
  top: 16px;
  left: 22px;
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
}

.slide-controls .swiper-button-next i:after,
.slide-controls .swiper-button-prev i:after {
  content: '';
  width: 45px;
  height: 1px;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  -webkit-transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
  -o-transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
  transition: all 0.7s cubic-bezier(1, 0, 0.25, 1);
}

.slide-controls .swiper-button-next:hover {
  right: 45px;
}

.slide-controls .swiper-button-next:hover i {
  left: 30px;
}

.slide-controls .swiper-button-next:hover i:after {
  width: 20px;
}

.slide-controls .swiper-button-next i:after {
  top: 6px;
  right: 5px;
}

.slide-controls .swiper-button-prev {
  right: auto;
  left: 50px;
}

.slide-controls .swiper-button-prev:hover {
  left: 45px;
}

.slide-controls .swiper-button-prev:hover i {
  left: 10px;
}

.slide-controls .swiper-button-prev:hover i:after {
  width: 20px;
}

.slide-controls .swiper-button-prev i {
  left: 18px;
}

.slide-controls .swiper-button-prev i:after {
  top: 6px;
  left: 5px;
}

/* ====================== [ End Slider ] ====================== */
/* ====================== [ 10 Start About Section ] ====================== */
.about .items {
  padding: 60px 40px;
  -webkit-transition: border .3s;
  -o-transition: border .3s;
  transition: border .3s;
}

.about .items .icon {
  font-size: 70px;
  margin-bottom: 30px;
}

.about .items .icon svg {
  width: 1em;
  height: 1em;
}

.about .items h5 {
  font-size: 22px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.about .items .more-stroke {
  margin-top: 30px;
}

.about .items .more-stroke span {
  width: 0;
  height: 2px;
  background: #1a1d1c;
  position: relative;
}

.about .items .more-stroke span:after,
.about .items .more-stroke span:before {
  content: '';
  width: 0;
  height: 2px;
  background: #1a1d1c;
  position: absolute;
  right: -1px;
}

.about .items .more-stroke span:after {
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  top: -4px;
}

.about .items .more-stroke span:before {
  -webkit-transform: rotate(-40deg);
  -ms-transform: rotate(-40deg);
  transform: rotate(-40deg);
  top: 4px;
}

.about .items.active {
  background: #f9f9f9;
  -webkit-transition: border .4s, background .2s;
  -o-transition: border .4s, background .2s;
  transition: border .4s, background .2s;
}

.about .items.active .more-stroke span {
  width: 30px;
  -webkit-transition: width .3s;
  -o-transition: width .3s;
  transition: width .3s;
}

.about .items.active .more-stroke span:after,
.about .items.active .more-stroke span:before {
  width: 12px;
  -webkit-transition: width .3s;
  -o-transition: width .3s;
  transition: width .3s;
  -webkit-transition-delay: .3s;
  -o-transition-delay: .3s;
  transition-delay: .3s;
}

.about .items:hover .more-stroke span {
  width: 30px;
}

.about .items:hover .more-stroke span:after,
.about .items:hover .more-stroke span:before {
  width: 12px;
}

.about.blc {
  background-color: #080808;
  color: #fff;
}

.about.blc:before {
  background-image: url(../img/giphynoise/giphynoise.png);
  background-repeat: repeat;
  background-size: 40%;
  opacity: .015;
}

.about.blc .extra-text {
  color: #ddd;
}

.about.blc .sub-title {
  color: #ccc;
}

.about.blc .sub-title:after {
  background: #eee;
}

.about.blc .section-head h6 {
  color: #ccc;
}

.about.blc .items {
  border: 1px solid transparent;
}

.about.blc .items .icon {
  font-size: 60px;
  color: #eee;
}

.about.blc .items.active {
  background: #0e0e0e;
  border-color: rgba(255, 255, 255, 0.5);
}

.about.blc .items .more-stroke span,
.about.blc .items .more-stroke span:before,
.about.blc .items .more-stroke span:after {
  background: #fff;
}

.intro-section .item h6 {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin-bottom: 10px;
}

/* ====================== [ End About Section ] ====================== */
/* ====================== [ 11 Start Work ] ====================== */
.work-carousel.gray .swiper-slide:hover .img {
  -webkit-box-shadow: 0px 40px 30px -40px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 40px 30px -40px rgba(0, 0, 0, 0.3);
}

.work-carousel.gray .swiper-slide:hover .img .bg-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.work-carousel.gray .swiper-slide:hover .img:after {
  opacity: 1 !important;
}

.work-carousel.gray .swiper-slide:hover .content .cont h6,
.work-carousel.gray .swiper-slide:hover .content .cont h4 {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}

.work-carousel.gray .swiper-slide-active .img {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.work-carousel.gray .swiper-slide-active .img:after {
  opacity: 0 !important;
}

.work-carousel.gray .swiper-slide-active .content .cont h6 {
  -webkit-transform: translateY(-20px) !important;
  -ms-transform: translateY(-20px) !important;
  transform: translateY(-20px) !important;
}

.work-carousel.gray .swiper-slide-active .content .cont h4 {
  -webkit-transform: translateY(20px) !important;
  -ms-transform: translateY(20px) !important;
  transform: translateY(20px) !important;
}

.work-carousel.gray .swiper-slide-active .content .cont h6,
.work-carousel.gray .swiper-slide-active .content .cont h4 {
  opacity: 0 !important;
  visibility: hidden !important;
}

.work-carousel.gray .content .img {
  width: 100%;
  height: 500px;
}

.work-carousel.gray .content .img .bg-img {
  width: 100%;
  height: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.work-carousel.gray .content .cont h6 {
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.work-carousel.gray .content .cont h4 {
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  -webkit-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}

.work-carousel .swiper-slide {
  padding: 0px 10px 50px;
}

.work-carousel .swiper-slide .img {
  border-radius: 3px;
  overflow: hidden;
  -webkit-transition: all .8s;
  -o-transition: all .8s;
  transition: all .8s;
}

.work-carousel .swiper-slide-active .img {
  -webkit-box-shadow: 0px 40px 30px -40px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 40px 30px -40px rgba(0, 0, 0, 0.3);
}

.work-carousel .swiper-slide-active .img:after {
  opacity: 1 !important;
}

.work-carousel .swiper-slide-active .cont h6,
.work-carousel .swiper-slide-active .cont h4 {
  opacity: 1 !important;
  visibility: visible !important;
  -webkit-transform: translateY(0px) !important;
  -ms-transform: translateY(0px) !important;
  transform: translateY(0px) !important;
}

.work-carousel .swiper-pagination-progressbar {
  position: absolute;
  bottom: 0;
  top: auto;
  right: auto;
  left: calc(50% - 120px);
  width: 240px;
  height: 2px;
  border-radius: 10px;
  background: #eee;
}

.work-carousel .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  border-radius: 10px;
  background: #1e1e1e;
}

.work-carousel .swiper-button-next,
.work-carousel .swiper-button-prev {
  color: #000;
  width: 60px;
  height: 60px;
  border-radius: 0;
  border: 0;
  background: #fff;
  opacity: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.work-carousel .swiper-button-next:after,
.work-carousel .swiper-button-prev:after {
  display: none;
}

.work-carousel:hover .swiper-button-next,
.work-carousel:hover .swiper-button-prev {
  opacity: 1;
}

.work-carousel .content {
  position: relative;
}

.work-carousel .content .img {
  position: relative;
  line-height: 0;
}

.work-carousel .content .img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.7)), to(transparent));
  background: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.7), transparent);
  background: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.7), transparent);
  background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);
  opacity: 0;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.work-carousel .content .cont {
  position: absolute;
  bottom: 40px;
  left: 40px;
  color: #fff;
}

.work-carousel .content .cont h6 {
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.work-carousel .content .cont h4 {
  font-weight: 700;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

.work-carousel.mcolumn .swiper-slide {
  padding: 0px;
}

.work-carousel.mcolumn .swiper-slide .img {
  border-radius: 0;
}

.work-carousel.mcolumn .swiper-slide-active .img {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.work-carousel.mcolumn .swiper-slide-active .img:after {
  opacity: 1 !important;
}

.portfolio .filtering {
  text-align: center;
  margin-bottom: 20px;
}

.portfolio .filtering span {
  font-weight: 500;
  margin: 0 20px;
  cursor: pointer;
  position: relative;
}

.portfolio .filtering span.active {
  color: #24d5b4;
}

.portfolio .filtering span:after {
  content: '';
  width: 7px;
  height: 7px;
  background: #eee;
  border-radius: 50%;
  position: absolute;
  right: -24px;
  top: 40%;
}

.portfolio .filtering span:last-of-type:after {
  display: none;
}

.portfolio .gallery .items {
  margin-top: 40px;
  padding: 0 20px;
}

.portfolio .gallery .items .item-img {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.portfolio .gallery .items .item-img>a {
  display: block;
}

.portfolio .gallery .items .item-img:hover img {
  -webkit-filter: blur(2px);
  filter: blur(2px);
}

.portfolio .gallery .items .item-img:hover .item-img-overlay {
  opacity: 1;
  visibility: visible;
}

.portfolio .gallery .items .item-img:hover .overlay-info {
  opacity: 1;
}

.portfolio .gallery .items .item-img:hover .overlay-info .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(20ms * var(--char-index));
  animation-delay: calc(20ms * var(--char-index));
}

.portfolio .gallery .items .item-img img {
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.portfolio .gallery .items .item-img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(38, 38, 40, 0.5);
  text-align: center;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
  opacity: 0;
  visibility: hidden;
}

.portfolio .gallery .items .overlay-info {
  opacity: 0;
}

.portfolio .gallery .items .overlay-info h5 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  text-transform: lowercase;
}

.portfolio.gutter .filtering {
  margin-bottom: 0;
}

.portfolio.gutter .gallery .items {
  margin-top: 80px;
  padding: 0 40px;
}

.portfolio.gutter .gallery .items .item-img {
  border-radius: 0;
}

.portfolio.gutter .gallery .items .overlay-info h5 {
  font-size: 28px;
}

.slider-portfolio .swiper-slide {
  min-height: 100vh;
}

.slider-portfolio .swiper-slide .bg-img {
  height: 75vh;
  margin-top: 20vh;
  background-size: cover;
  background-position: center center;
}

.slider-portfolio .swiper-slide .caption {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 3;
}

.slider-portfolio .swiper-slide .caption h1 {
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity .5s,
    visibility .5s;
  -o-transition: opacity .5s,
    visibility .5s;
  transition: opacity .5s,
    visibility .5s;
}

.slider-portfolio .swiper-slide .caption h1 .stroke {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.slider-portfolio .swiper-slide .caption .dis {
  margin-top: 30px;
  color: #fff;
  font-size: 12px;
  letter-spacing: 4px;
  text-transform: uppercase;
  -webkit-transition: all .7s;
  -o-transition: all .7s;
  transition: all .7s;
  position: relative;
  padding-left: 80px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}

.slider-portfolio .swiper-slide .caption .dis:after {
  content: '';
  width: 60px;
  height: 1px;
  background: #eee;
  position: absolute;
  left: 0;
  top: 50%;
}

.slider-portfolio .swiper-slide.swiper-slide-active .caption h1 {
  -webkit-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}

.slider-portfolio .swiper-slide.swiper-slide-active .caption h1 .char {
  -webkit-animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.5s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(60ms * var(--char-index));
  animation-delay: calc(60ms * var(--char-index));
}

.slider-portfolio .swiper-slide.swiper-slide-active .caption h1,
.slider-portfolio .swiper-slide.swiper-slide-active .caption .dis {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
}

.slider-portfolio .swiper-slide.swiper-slide-active .caption .dis {
  -webkit-transition-delay: .5s;
  -o-transition-delay: .5s;
  transition-delay: .5s;
}

/* ====================== [ End Work ] ====================== */
/* ====================== [ 12 Start Process Section ] ====================== */
.process .section-head {
  margin-bottom: 20px;
}

.process .bg-box {
  position: relative;
}

.process .background {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  bottom: 80px;
  background-size: cover;
  background-position: center center;
}

.process .background .vid {
  position: absolute;
  left: 25%;
  top: calc(50% - 50px);
  text-align: center;
  z-index: 8;
}

.process .background .vid .vid-butn {
  width: 100%;
}

.process .background .vid .vid-butn:hover .icon {
  color: #fff;
}

.process .background .vid .vid-butn:hover .icon:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}

.process .background .vid .vid-butn .icon {
  color: #212529;
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 17px;
  position: relative;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.process .background .vid .vid-butn .icon:after {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  border: 1px solid #eee;
  border-radius: 50%;
  z-index: -1;
}

.process .background .vid .vid-butn .icon:before {
  content: '';
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  background: #fff;
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}

.process .content {
  padding: 80px 40px;
  background: #000;
  color: #fff;
}

.process .content h4 {
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 50px;
}

.process .content ul {
  margin: 0;
}

.process .content ul li {
  margin-bottom: 30px;
}

.process .content ul li span {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
  font-size: 60px;
  display: table-cell;
  width: 100px;
  line-height: 1;
}

.process .content ul li .cont {
  display: table-cell;
  vertical-align: middle;
}

.process .content ul li .cont h5 {
  font-size: 17px;
  margin-bottom: 10px;
}

.process .content ul li .cont p {
  font-size: 14px;
  font-weight: 300;
  color: #eee;
  opacity: .7;
}

.process .content ul li:last-of-type {
  margin-bottom: 0;
}

.containerFirst {
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  display: flex;  
  align-items: center;
}

@media screen and (max-width: 560px) {
  .containerFirst {
    height: 70vh;
  }
}

.videoBackground {
position: absolute;
top: 0;
left: 0;
background-size: cover;
width: 100%;
height: 100%;
object-fit: cover;
z-index: 0;
}

/* ====================== [ End Process Section ] ====================== */
/* ====================== [ 13 Start Testimonials ] ====================== */
.testimonials {
  position: relative;
  z-index: 3;
}

.testimonials:after {
  content: '';
  width: 58.333333%;
  position: relative;
  top: -80px;
  bottom: 0;
  left: 0;
  background: #fafafa;
  z-index: -1;
}

/*.testimonials.no-bg {
    padding-top: 0px;
    z-index: -1; }
    .testimonials.no-bg:after {
      display: none; }
    .testimonials.no-bg .text-bg {
      position: relative;
      left: -10%;
      top: -40px;
      margin-bottom: 60px;
      font-size: 11vw;
      font-weight: 900;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: #555;
      color: transparent;
      line-height: 1;
      opacity: .2;
      z-index: -1; } */
.testimonials .title h5 {
  color: #ccc;
  font-size: 22px;
  font-weight: 800;
  letter-spacing: 2px;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  -ms-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
}

.testimonials .quote-text {
  position: relative;
}

.testimonials .quote-text .quote-icon {
  position: absolute;
  right: 100px;
  bottom: -30px;
  width: 70px;
}

.testimonials p {
  font-size: 22px;
  font-weight: 300;
  line-height: 2;
  color: #18171B;
}

.testimonials .info {
  text-align: center;
}

.testimonials .author {
  width: 90px;
  height: 90px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}

.testimonials h6 {
  font-weight: 700;
  letter-spacing: 2px;
  padding-top: 15px;
}

.testimonials h6 span {
  display: block;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: #212529;
  margin-top: 10px;
}

.testimonials .navs span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #212529;
  font-size: 18px;
  position: absolute;
  top: 50%;
  cursor: pointer;
}

.testimonials .navs span:hover {
  background: #212529;
  color: #fff;
}

.testimonials .navs .next {
  right: 30px;
}

.testimonials .navs .prev {
  left: 30px;
}

/* ====================== [ End Testimonials ] ====================== */
/* ====================== [ 14 Start Agency Section ] ====================== */
.agency .content h4 {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.agency .content ul {
  margin: 0;
}

.agency .content ul li {
  margin-top: 25px;
  padding-left: 25px;
  position: relative;
}

.agency .content ul li:after {
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #999;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 2px;
}

.agency .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.agency .img .imgone {
  width: 55%;
  position: relative;
  bottom: -80px;
  margin-bottom: 80px;
  border-radius: 4px;
  border: 5px solid #fff;
  -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.agency .img .imgtwo {
  width: 55%;
  position: absolute;
  right: 0;
  border-radius: 4px;
  border: 5px solid #fff;
  -webkit-box-shadow: -15px 10px 30px rgba(0, 0, 0, 0.05);
  box-shadow: -15px 10px 30px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  z-index: 2;
}

.agency .img .icon {
  width: 100px;
  height: 100px;
  line-height: 135px;
  text-align: center;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  bottom: 20%;
  left: 40%;
  z-index: 3;
}

.agency .img .icon:after {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: -1;
}

.agency .img .icon svg {
  fill: #24d5b4;
  width: 90px;
}

.agency .img .exp {
  position: absolute;
  right: 0;
  bottom: 0;
}

.agency .img .exp h6 {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  position: relative;
}

.agency .img .exp h6:after {
  content: '';
  width: 30px;
  height: 1px;
  background: #24d5b4;
  position: absolute;
  left: -40px;
  top: 45%;
}

.agency .img .exp h2 {
  font-size: 70px;
  font-weight: 700;
}

.agency .imgsec {
  padding-right: 30px;
}

.agency .imgsec .imgone {
  position: relative;
  top: -20px;
}

.agency .imgsec .imgtwo {
  position: relative;
  bottom: -20px;
}

.agency .imgsec .exp {
  padding-left: 40px;
}

.agency .imgsec .exp h6 {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  position: relative;
}

.agency .imgsec .exp h6:after {
  content: '';
  width: 30px;
  height: 1px;
  background: #24d5b4;
  position: absolute;
  left: -40px;
  top: 45%;
}

.agency .imgsec .exp h2 {
  font-size: 70px;
  font-weight: 700;
}

.about-us .img {
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

/* ====================== [ End Agency Section ] ====================== */
/* ====================== [ 15 Start min-area ] ====================== */
.min-area .img {
  padding: 120px 0 120px 80px;
  position: relative;
  z-index: 3;
}

.min-area .img:after {
  content: '';
  position: absolute;
  top: 0;
  right: 120px;
  bottom: 0;
  left: -300px;
  background: #f3f3f3;
  z-index: -1;
}

.min-area .content {
  padding-left: 40px;
}

.min-area .content h4 {
  font-weight: 700;
  font-size: 40px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.min-area .content ul {
  margin: 0;
}

.min-area .content ul li {
  margin-top: 25px;
  padding-left: 25px;
  position: relative;
}

.min-area .content ul li:after {
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #999;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 2px;
}

.min-area .content ul.feat li {
  padding: 0 0 30px 0;
  border-bottom: 1px solid #eee;
}

.min-area .content ul.feat li:last-of-type {
  padding: 0;
  border: 0;
}

.min-area .content ul.feat li:after {
  display: none;
}

.min-area .content ul.feat li h6 {
  margin-bottom: 5px;
}

.min-area .content ul.feat li h6 span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 50%;
  margin-right: 10px;
}

.min-area .content ul.feat li p {
  padding-left: 45px;
}

/* ====================== [ End min-area ] ====================== */
/* ====================== [ 15 Start Brands Section ] ====================== */
.clients .brands {
  padding: 100px 10px;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.08);
}

.clients .brands:last-of-type {
  border-right: 0;
}

.clients .brands:hover .img {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.clients .brands:hover .link {
  opacity: 1 !important;
  -webkit-transition: all 0;
  -o-transition: all 0;
  transition: all 0;
}

.clients .brands:hover .char {
  -webkit-animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation: fadeInUp 0.3s cubic-bezier(0.3, 0, 0.7, 1) both;
  -webkit-animation-delay: calc(20ms * var(--char-index));
  animation-delay: calc(20ms * var(--char-index));
}

.clients .brands .item {
  position: relative;
  text-align: center;
}

.clients .brands .item .link {
  font-size: 16px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
}

.clients .brands .img {
  max-width: 85px;
  margin: auto;
  position: relative;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  transition: all .4s;
}

.clients .bord {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

/* ====================== [ End Brands Section ] ====================== */
/* ====================== [ 17 Start Team ] ====================== */
.team .item .img {
  position: relative;
  overflow: hidden;
}

.team .item .img .info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-top: 15px;
  background: #fff;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.team .item .img .info h6 {
  font-size: 16px;
  margin-bottom: 2px;
}

.team .item .img .info .social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
}

.team .item .img .info .social a {
  width: 100%;
  padding: 5px;
  border-right: 1px solid #eee;
}

.team .item .img .info .social a:last-of-type {
  border: 0;
}

.team .item .img:hover .info {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.team .item .img.filter img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.team .item .img img {
  -webkit-transition: -webkit-filter .5s !important;
  transition: -webkit-filter .5s !important;
  -o-transition: filter .5s !important;
  transition: filter .5s !important;
  transition: filter .5s, -webkit-filter .5s !important;
}

/* ====================== [ End Team ] ====================== */
/* ====================== [ 18 Start Blog ] ====================== */
.blog .stories {
  position: relative;
  padding: 20px;
}

.blog .controls {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  background: #fff;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 3;
}

.blog .controls:after {
  content: '';
  position: absolute;
  right: -10px;
  top: -10px;
  bottom: -10px;
  left: -10px;
  background: rgba(220, 220, 220, 0.2);
  border-radius: 50%;
  z-index: -1;
}

.blog .controls .swiper-button-next.next-ctrl,
.blog .controls .swiper-button-prev.prev-ctrl {
  position: absolute;
  left: calc(50% - 15px);
  right: auto;
  color: #ccc;
  z-index: 4;
  cursor: pointer;
  font-size: 28px;
  width: 30px;
  height: 30px;
}

.blog .controls .swiper-button-next.next-ctrl:after,
.blog .controls .swiper-button-prev.prev-ctrl:after {
  display: none;
}

.blog .controls .swiper-button-next.next-ctrl {
  top: 22px;
  bottom: auto;
}

.blog .controls .swiper-button-prev.prev-ctrl {
  bottom: 0px;
  top: auto;
}

.blog .controls .swiper-pagination-fraction {
  position: static;
}

.blog .controls .swiper-pagination-fraction span {
  margin: 0 7px;
  font-weight: 600;
  color: #24d5b4;
}

.blog .controls .swiper-pagination-fraction .swiper-pagination-current {
  position: relative;
}

.blog .controls .swiper-pagination-fraction .swiper-pagination-current:after {
  content: "/";
  position: absolute;
  right: -11px;
  top: 0;
  font-size: 10px;
  color: #ccc;
}

.blog .item .img {
  border-radius: 3px;
  overflow: hidden;
}

.blog .item .content {
  padding: 30px 65px;
  text-align: center;
}

.blog .item .content .tags {
  color: #212529;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 13px;
  margin-bottom: 20px;
}

.blog .item .content .tags a {
  margin: 0 7px;
}

.blog .item .content .info {
  margin-bottom: 10px;
}

.blog .item .content .info a {
  margin: 0 10px;
  font-weight: 400;
  color: #777;
}

.blog .item .content .title {
  margin-bottom: 10px;
}

.blog .item .content .title h4 {
  font-weight: 700;
  line-height: 1.5;
}

.blog .item .content .more a {
  color: #212529;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  margin-top: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.blog-grid {
  position: relative;
}

.blog-grid .bg-pattern {
  position: absolute;
  width: 100%;
  height: 50%;
  left: 0;
  top: 0;
  z-index: -1;
}

.blog-grid .item .post-img .img {
  overflow: hidden;
}

.blog-grid .item .post-img:hover img {
  -webkit-transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.blog-grid .item .post-img img {
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.blog-grid .item .cont {
  text-align: center;
  position: relative;
  padding: 40px 30px;
  background: #fafafa;
}

.blog-grid .item .info {
  margin-bottom: 15px;
}

.blog-grid .item .info a {
  color: #bababa;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  margin-right: 10px;
}

.blog-grid .item .info a:last-of-type {
  margin-right: 0;
}

.blog-grid .item .info a i {
  margin-right: 2px;
}

.blog-grid .item .info .tag {
  color: #24d5b4;
  font-weight: 600;
  float: right;
}

.blog-grid .item h5 {
  font-size: 22px;
  font-weight: 700;
  line-height: 1.7;
  margin-bottom: 15px;
}

.blog-grid .item .more {
  color: #bababa;
  border-bottom: 1px solid #bababa;
}

.blog-pg .posts .item .content {
  padding-top: 25px;
}

.blog-pg .posts .item .content .tags {
  margin-bottom: 10px;
}

.blog-pg .posts .item .content .tags a {
  font-size: 12px;
  font-weight: 500;
  color: #888;
  padding-right: 20px;
  position: relative;
}

.blog-pg .posts .item .content .tags a:after {
  content: '';
  width: 8px;
  height: 2px;
  background: #ccc;
  position: absolute;
  right: 4px;
  top: 50%;
}

.blog-pg .posts .item .content .tags a:last-of-type {
  padding-right: 0;
}

.blog-pg .posts .item .content .tags a:last-of-type:after {
  display: none;
}

.blog-pg .posts .item .content h4 {
  font-weight: 700;
  line-height: 1.6;
  margin-bottom: 5px;
}

.blog-pg .posts .item .content .more {
  margin-top: 20px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 60px;
  position: relative;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.blog-pg .posts .item .content .more:after {
  content: '';
  width: 50px;
  height: 1px;
  background: #090909;
  position: absolute;
  left: 0px;
  top: 50%;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.blog-pg .posts .item .content .more:hover {
  padding-left: 40px;
}

.blog-pg .posts .item .content .more:hover:after {
  width: 30px;
}

.blog-pg .posts .item .content .date {
  width: 100%;
  text-align: right;
}

.blog-pg .posts .item .content .date a {
  padding: 15px 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
}

.blog-pg .posts .item .content .date .num {
  display: block;
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 5px;
}

.blog-pg .posts .pagination {
  margin: 80px auto 0;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}

.blog-pg .posts .pagination span {
  text-align: center;
  margin: 0 2px;
}

.blog-pg .posts .pagination span a {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f5f5f5;
  font-size: 18px;
}

.blog-pg .posts .pagination span.active a {
  background: #090909;
  color: #fff;
}

.blog-pg.single {
  position: relative;
  margin-top: -100px;
}

.blog-pg.single .post .img {
  min-height: 80vh;
}

.blog-pg.single .post .content p {
  color: #555;
  font-size: 16px;
  font-family: inherit;
  margin: 30px 0;
}

.blog-pg.single .post .content ul li {
  margin: 10px 0;
}

.blog-pg.single .post .content ul li span {
  margin-right: 10px;
  font-weight: 500;
  font-size: 13px;
}

.blog-pg.single .post .content .quotes {
  background: #fafafa;
  padding: 30px;
  margin: 30px 0;
}

.blog-pg.single .post .content .quotes p {
  color: #111;
  font-size: 18px;
  font-style: italic;
}

.blog-pg.single .post .content .spacial p {
  color: #111;
  font-size: 18px;
  font-weight: 500;
}

.blog-pg.single .post .content .share-info {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px solid #999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-pg.single .post .content .share-info .social a {
  font-size: 13px;
  margin-right: 20px;
}

.blog-pg.single .post .content .share-info .tags {
  margin-left: auto;
}

.blog-pg.single .post .content .share-info .tags a {
  font-size: 12px;
  margin: 0 10px;
}

.blog-pg.single .post .author {
  padding: 30px;
  margin-top: 40px;
  background: #fafafa;
}

.blog-pg.single .post .author .author-img {
  display: table-cell;
  vertical-align: middle;
  width: 140px;
}

.blog-pg.single .post .author .info {
  display: table-cell;
  padding-left: 40px;
}

.blog-pg.single .post .author .info h6 {
  margin-bottom: 10px;
  font-size: 15px;
}

.blog-pg.single .post .author .info h6 span {
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 10px;
}

.blog-pg.single .post .author .info p {
  padding: 0;
  margin: 0;
}

.blog-pg.single .post .author .info .social {
  margin-top: 15px;
  font-size: 13px;
}

.blog-pg.single .post .author .info .social a {
  margin-right: 15px;
}

.blog-pg.single .pagination {
  margin-top: 80px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.blog-pg.single .pagination span {
  width: 100%;
  padding: 20px;
  border-right: 1px solid #eee;
  border-left: 1px solid #eee;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 13px;
  letter-spacing: 1px;
}

.blog-pg.single .pagination .icon {
  border: 0;
  width: 70px;
}

.blog-pg.single .comments-area {
  margin-top: 80px;
}

.blog-pg.single .comments-area .item {
  padding: 30px 0;
  margin: 30px 0;
  border-bottom: 1px solid #eee;
}

.blog-pg.single .comments-area .item.relped {
  margin-left: 100px;
}

.blog-pg.single .comments-area .item .comment-img {
  display: table-cell;
  width: 80px;
}

.blog-pg.single .comments-area .item .info {
  display: table-cell;
  vertical-align: middle;
  padding-left: 40px;
  position: relative;
}

.blog-pg.single .comments-area .item .info h6 {
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
}

.blog-pg.single .comments-area .item .info h6 span {
  color: #999;
  font-size: 12px;
  font-weight: 400;
  margin-left: 10px;
}

.blog-pg.single .comments-area .item .info p {
  line-height: 1.5;
  margin-top: 10px;
}

.blog-pg.single .comments-area .item .info .replay {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 13px;
}

.blog-pg.single .comments-area .item .info .replay i {
  margin-left: 5px;
}

.blog-pg.single .comment-form {
  margin-top: 80px;
}

.blog-pg.single .comment-form form {
  margin-top: 50px;
}

.blog-pg.single .comment-form form input,
.blog-pg.single .comment-form form textarea {
  width: 100%;
  padding: 17px;
  background: #fafafa;
  border: 0;
}

.blog-pg.single .comment-form form textarea {
  height: 140px;
}

.blog-pg.single .comment-form form button {
  cursor: pointer;
}

/* ====================== [ End Blog ] ====================== */
/* ====================== [ 19 Start Contact ] ====================== */
.contact .form input,
.contact .form textarea {
  width: 100%;
  padding: 15px;
  background: transparent;
  border: 1px solid #ddd;
}

.contact .form textarea {
  height: 160px;
}

.contact .cont-info .extra-title {
  font-size: 28px;
  margin-bottom: 15px;
}

.contact .cont-info h5 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
}

.contact .cont-info h5:last-of-type {
  color: #222;
  font-weight: 800;
}

.contact .cont-info h6 {
  line-height: 1.6;
  font-size: 16;
  font-weight: 400;
}

/* ====================== [ End Contact ] ====================== */
/* ====================== [ 20 Start call-action ] ====================== */
.call-action {
  background-color: #020202;
  color: #fff;
}

.call-action.gray {
  background: #f8f8f8;
}

.call-action.gray .content h6 {
  color: #777;
  font-size: 14px;
  font-weight: 500;
}

.call-action.gray .content h6 span {
  color: #777;
}

.call-action.gray .content h2 {
  font-size: 5vw;
  font-weight: 700;
  margin-bottom: 20px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #333;
}

.call-action.gray .content h2 b span {
  color: #333;
  -webkit-text-stroke-width: 0;
}

.call-action.gray .tlink {
  border-bottom: 1px solid #333;
}

.call-action:before {
  background-image: url(../img/giphy_gif/giphy.gif);
  background-repeat: repeat;
  opacity: .04;
}

.call-action.nogif {
  position: relative;
  padding: 80px 0;
  background: #0a0a0a;
  z-index: 2;
  overflow: hidden;
}

.call-action.nogif:before {
  display: none;
}

.call-action.nogif:after {
  content: '';
  height: 0;
  width: 100%;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  -webkit-transition: height 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  -o-transition: height 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: height 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: #0c0c0c;
  z-index: -1;
}

.call-action.nogif:hover:after {
  height: 100%;
  top: 0;
  bottom: auto;
}

.call-action .content h6 {
  color: #24d5b4;
  font-size: 13px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 4px;
  margin-bottom: 10px;
}

.call-action .content h6 span {
  color: #24d5b4;
}

.call-action .content h2 {
  font-size: 6vw;
  font-weight: 800;
  margin-bottom: 20px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #fff;
  color: transparent;
}

.call-action .content h2 span {
  color: transparent;
}

.call-action .content h2 .word {
  margin-right: 15px;
}

.call-action .content h2 b span {
  color: #fff;
  -webkit-text-stroke-width: 0;
}

.call-action .content p {
  font-size: 17px;
  font-weight: 300;
}

.call-action .tlink {
  margin-left: auto;
  border-bottom: 1px solid #eee;
}

.call-action.next {
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.call-action.next:after {
  display: none;
}

.call-action.next:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  background: #0a0a0a;
  opacity: 1;
  -webkit-transition: all .5s;
  -o-transition: all .5s;
  transition: all .5s;
}

.call-action.next .content h2 {
  font-size: 5vw;
  font-weight: 700;
}

.call-action.next .content h2 b {
  font-weight: 700;
}

.call-action.next .nxt-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: scale(1.4);
  -ms-transform: scale(1.4);
  transform: scale(1.4);
  background-size: cover;
  background-position: center;
  z-index: -1;
  -webkit-transition: all 1s linear;
  -o-transition: all 1s linear;
  transition: all 1s linear;
}

.call-action.next:hover:before {
  opacity: .9;
}

.call-action.next:hover .nxt-img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 10s linear;
  -o-transition: all 10s linear;
  transition: all 10s linear;
}

/* ====================== [ End call-action ] ====================== */
/* ====================== [ 21 Start Fotter ] ====================== */
.footer-sm {
  background: #060606;
  padding: 40px 0;
}

.footer-sm.light {
  background: #fff;
}

.footer-sm .right p {
  margin-left: auto;
  text-align: right;
}

/* ====================== [ End Footer ] ====================== */
/* ====================== [ 22 Start Dark-Theme ] ====================== */
section.dark {
  color: #fff;
  background: #090909;
}

section.dark .main-content {
  background: #090909;
}

section.dark p {
  color: #b7b7b7;
}

section.dark .pace {
  background: #080808;
}

section.dark .pace .pace-progress {
  background: #ccc;
}

section.dark #preloader:after,
section.dark #preloader:before {
  background: #111;
}

section.dark .extra-text {
  color: #ddd;
}

section.dark .sub-title {
  color: #ccc;
}

section.dark .sub-title:after {
  background: #eee;
}

section.dark .section-head h6 {
  color: #ccc;
}

section.dark .bg-gray {
  background: #1e1e1e;
}

section.dark .bg-pattern:after {
  background: #000;
  opacity: .96;
}

section.dark .cursor {
  background: #eee;
}

section.dark .cursor2 {
  background: rgba(255, 255, 255, 0.1);
}

section.dark .imgio .cimgio {
  background: #0e0e0e;
}

section.dark .about .items.active {
  background: #0e0e0e;
}

section.dark .about .items .more-stroke span,
section.dark .about .items .more-stroke span:before,
section.dark .about .items .more-stroke span:after {
  background: #fff;
}

section.dark .work-carousel .swiper-pagination-progressbar {
  background: #1e1e1e;
}

section.dark .work-carousel .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #555;
}

section.dark .portfolio .filtering span:after {
  background: #484848;
}

section.dark .testimonials:after {
  background: #0e0e0e;
}

section.dark .testimonials p {
  color: #eee;
}

section.dark .testimonials h6 span {
  color: #999;
}

section.dark .testimonials .navs span {
  border-color: rgba(255, 255, 255, 0.2);
  color: #ccc;
}

section.dark .testimonials .quote-text .quote-icon img {
  -webkit-filter: invert(20%);
  filter: invert(20%);
}

section.dark .agency .img .imgone,
section.dark .agency .img .imgtwo {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #0e0e0e;
}

section.dark .agency .img .icon {
  background: #111;
}

section.dark .min-area .img:after,
section.dark .blog-grid .item .cont {
  background: #101010;
}

section.dark .min-area .content ul.feat li,
section.dark .min-area .content ul.feat li h6 span {
  border-color: #1b1a1a;
}

section.dark .team .item .img .info {
  background: #090909;
}

section.dark .team .item .img .info .social a {
  border-color: #0e0e0e;
}

section.dark .blog .item .content .more a,
section.dark .blog .item .content .tags {
  color: #fff;
}

section.dark .blog .item .content .info a {
  color: #999;
}

section.dark .blog .controls {
  background: #000;
}

section.dark .blog-pg .posts .item .content .tags a {
  color: #ccc;
}

section.dark .blog-pg .posts .item .content .tags a:after {
  background: #ccc;
}

section.dark .blog-pg .posts .item .content .more:after {
  background: #fff;
}

section.dark .blog-pg .posts .item .content .date a {
  border-color: #656363;
}

section.dark .blog-pg .posts .pagination span a {
  background: #111;
}

section.dark .blog-pg .posts .pagination span.active a {
  background: #fff;
  color: #000;
}

section.dark .blog-pg.single .post .content .spacial p {
  color: #eee;
}

section.dark .blog-pg.single .post .content p {
  color: #999;
}

section.dark .blog-pg.single .post .content .quotes,
section.dark .blog-pg.single .post .author,
section.dark .blog-pg.single .comment-form form input,
section.dark .blog-pg.single .comment-form form textarea {
  background: #121212;
  color: #fff;
}

section.dark .blog-pg.single .pagination,
section.dark .blog-pg.single .pagination span,
section.dark .blog-pg.single .comments-area .item {
  border-color: #5d5d5d;
}

section.dark .clients .brands,
section.dark .clients .bord {
  border-color: rgba(200, 200, 200, 0.08);
}

section.dark .contact .cont-info h5:last-of-type {
  color: #fff;
}

section.dark .contact .cont-info h5,
section.dark .contact .cont-info h6 {
  color: #eee;
}

section.dark .contact .form input,
section.dark .contact .form textarea {
  color: #fff;
}

/* ====================== [ Logo ] ====================== */

.logo {
  position: fixed;
  top: 36px;
  left: 36px;
  transition: transform 0.3s ease;
}

.logo img {
  width: 70px; /* Tamanho original da logo */
}


/* ====================== [ End Logo ] ====================== */


/* ====================== [ Button ] ====================== */

.button-flags {
  position: fixed;
  top: 60px;
  left: 90%;
  width: 100%;
  z-index: 100;
}

.buttonBRA {
  margin-right: 12px;
  border: none;
  background-color: transparent;

}

.buttonUSA {
  margin-right: 12px;
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  
}

.rounded-lg{
  border-radius: 50%;
}


@media (max-width: 768px) {
  .button-flags {
    width: 100%;
    top: 50px;
    left: 75%;
  }
}  


/* ====================== [ End Button ] ====================== */


/* ====================== [ End Dark-Theme ] ====================== */
/* ====================== [ 23 Start Responsive ] ====================== */
@media screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media screen and (max-width: 991px) {
  .md-mb10 {
    margin-bottom: 10px;
  }

  .md-mb20 {
    margin-bottom: 20px;
  }

  .md-mb30 {
    margin-bottom: 30px;
  }

  .md-mb40 {
    margin-bottom: 40px;
  }

  .md-mb50 {
    margin-bottom: 50px;
  }

  .md-mb60 {
    margin-bottom: 60px;
  }

  .md-mb70 {
    margin-bottom: 70px;
  }

  .md-mb80 {
    margin-bottom: 80px;
  }

  .md-pb10 {
    padding-bottom: 10px;
  }

  .md-pb20 {
    padding-bottom: 20px;
  }

  .md-pb30 {
    padding-bottom: 30px;
  }

  .md-pb40 {
    padding-bottom: 40px;
  }

  .md-pb50 {
    padding-bottom: 50px;
  }

  .md-pb60 {
    padding-bottom: 60px;
  }

  .md-pb70 {
    padding-bottom: 70px;
  }

  .md-pb80 {
    padding-bottom: 80px;
  }

  .slide-full .swiper-slide .bg-img,
  .slider-portfolio .swiper-slide .bg-img,
  .slider .parallax-slider .swiper-slide .bg-img {
    background-position: center center;
  }

  .slider .parallax-slider .caption,
  .slider-portfolio .swiper-slide .caption,
  .slide-full .capt {
    text-align: center;
  }

  .slider .parallax-slider .caption h1,
  .slider-portfolio .swiper-slide .caption h1,
  .slide-full .capt h1 {
    font-size: 50px;
  }

  .slider-portfolio .swiper-slide .bg-img {
    height: 50vh;
    margin-top: 30vh;
  }

  .slide-controls .swiper-button-next,
  .slide-controls .swiper-pagination-fraction {
    right: 25px;
  }

  .slide-controls .swiper-button-prev,
  .slide-full .social,
  .slider .parallax-slider .social {
    left: 25px;
  }

  .slide-controls.swiper-button-next:hover {
    right: 15px;
  }

  .slide-controls .swiper-button-prev:hover {
    left: 15px;
  }

  .intro-section .htit {
    margin-bottom: 30px;
  }

  .agency .imgsec {
    margin-bottom: 100px;
  }

  .agency .content h4 br {
    display: none;
  }

  .min-area .content {
    padding: 120px 0;
  }

  .process .section-head {
    margin-bottom: 80px;
  }

  .process .background {
    position: relative;
    top: 0;
    bottom: 0;
    height: 350px;
  }

  .process .background .vid {
    left: calc(50% - 50px);
    top: calc(50% - 50px);
  }

  .testimonials:after {
    top: 0;
    bottom: 0;
    width: 100%;
  }

  .testimonials .slider-for {
    margin-bottom: 30px;
  }

  .testimonials .title h5 {
    display: none;
  }

  .agency .img {
    margin-top: 80px;
  }

  .blog .item .content {
    padding: 30px 0px 70px;
  }

  .blog .controls {
    width: 80px;
    height: 80px;
    line-height: 80px;
    top: calc(100% - 40px);
    left: calc(50% - 40px);
  }

  .call-action .content h2 .word {
    margin-right: 3px;
  }

  .clients .brands {
    padding: 50px 10px;
  }

  .team {
    padding-top: 0;
  }

  .page-header .img-wrapper .title h3 {
    font-size: 70px;
  }

  .contact .form {
    margin-bottom: 50px;
  }

  .portfolio.gutter .gallery .items {
    margin-top: 60px;
    padding: 0 30px;
  }

  .page-header.proj-det .img-wrapper {
    min-height: 60vh;
  }
}

@media screen and (max-width: 767px) {
  .sm-mb10 {
    margin-bottom: 10px;
  }

  .sm-mb20 {
    margin-bottom: 20px;
  }

  .sm-mb30 {
    margin-bottom: 30px;
  }

  .sm-mb40 {
    margin-bottom: 40px;
  }

  .sm-mb50 {
    margin-bottom: 50px;
  }

  .sm-mb60 {
    margin-bottom: 60px;
  }

  .sm-mb70 {
    margin-bottom: 70px;
  }

  .sm-mb80 {
    margin-bottom: 80px;
  }

  .sm-pb10 {
    padding-bottom: 10px;
  }

  .sm-pb20 {
    padding-bottom: 20px;
  }

  .sm-pb30 {
    padding-bottom: 30px;
  }

  .sm-pb40 {
    padding-bottom: 40px;
  }

  .sm-pb50 {
    padding-bottom: 50px;
  }

  .sm-pb60 {
    padding-bottom: 60px;
  }

  .sm-pb70 {
    padding-bottom: 70px;
  }

  .sm-pb80 {
    padding-bottom: 80px;
  }

  h1 {
    font-size: 35px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  .hamenu .menu-links .main-menu>li {
    font-size: 24px;
  }

  .hamenu .menu-links .main-menu .sub-menu li {
    font-size: 18px;
  }

  .topnav .container-fluid {
    padding: 0 30px;
    
  }

  .section-padding,
  .min-area .content {
    padding: 100px 0;
  }

  .section-head h6 {
    letter-spacing: 2px;
    font-size: 12px;
  }

  /*.topnav .logo {
    width: 60px;
  }*/

  .section-head {
    margin-bottom: 60px !important;
  }

  .slider .parallax-slider .caption h1,
  .slide-full .capt h1,
  .slider-portfolio .swiper-slide .caption h1 {
    font-size: 40px;
  }

  .slider .parallax-slider .caption p {
    padding: 0 30px;
  }

  .extra-title,
  .agency .content h4,
  .min-area .content h4 {
    font-size: 30px;
  }

  .agency .imgsec .imgtwo {
    display: none;
  }

  .agency .imgsec .imgone:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
  }

  .agency .imgsec .exp {
    right: 30px;
    position: absolute;
    bottom: 30px;
    color: #fff;
    padding: 0;
    z-index: 2;
  }

  .agency .imgsec .exp h6 {
    color: #eee;
  }

  .footer-sm,
  .footer-sm .right p {
    text-align: center;
  }

  .progress-wrap {
    right: 20px;
    bottom: 20px;
    width: 30px;
    height: 30px;
  }

  .progress-wrap::after {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .clients .bord {
    border: 0;
  }

  .call-action .content h2,
  .call-action.next .content h2 {
    font-size: 50px;
  }

  .blog-pg .posts .item .content {
    position: relative;
  }

  .blog-pg .posts .item .content .col-10,
  .blog-pg .posts .item .content .col-2 {
    width: 100%;
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }

  .blog-pg .posts .item .content .col-2 {
    position: absolute;
    right: 0px;
    top: -90px;
    z-index: 3;
  }

  .blog-pg .posts .item .content .date a {
    padding: 10px;
    background: #fff;
    margin: 0;
  }

  .blog-pg .posts .item .content .date .num {
    font-size: 25px;
    margin: 0;
  }

  .blog-pg.single .comments-area .item.relped {
    margin-left: 0px;
  }

  .blog-pg.single .post .img {
    min-height: 50vh;
  }

  .blog-pg.single .comments-area .item .info h6 span {
    display: block;
    margin: 10px 0 0 0;
  }
}

@media screen and (max-width: 480px) {
  .hamenu .cont-info {
    display: none;
  }

  .hamenu .menu-links .main-menu>li {
    font-size: 20px;
    padding: 7px;
  }

  .hamenu .menu-links .main-menu .sub-menu li {
    font-size: 16px;
  }

  .topnav .container-fluid {
    padding: 0 15px;
  }

  .slide-full .capt .dis,
  .slider-portfolio .swiper-slide .caption .dis,
  .slider .parallax-slider .caption .dis {
    padding-left: 0;
    letter-spacing: 2px;
    margin-top: 20px;
  }

  .slide-full .capt .dis:after,
  .slider-portfolio .swiper-slide .caption .dis:after,
  .slider .parallax-slider .caption .dis:after {
    display: none;
  }

  .slide-controls .swiper-button-next {
    right: 5px;
  }

  .slide-controls .swiper-button-next i {
    left: 30px;
  }

  .slide-controls .swiper-button-next i:after {
    width: 20px;
  }

  .slide-controls .swiper-button-prev {
    left: 5px;
  }

  .slide-controls .swiper-button-prev i {
    left: 10px;
  }

  .slide-controls .swiper-button-prev i:after {
    width: 20px;
  }

  .slide-controls .swiper-button-next,
  .slide-controls .swiper-button-prev {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }

  .slide-controls .swiper-button-next:hover {
    right: 0 !important;
  }

  .slide-controls .swiper-button-prev:hover {
    left: 0 !important;
  }

  .slide-full .capt h1,
  .slider-portfolio .swiper-slide .caption h1,
  .slider .parallax-slider .caption h1 {
    font-size: 28px;
  }

  .slider .parallax-slider .caption p {
    display: none;
  }

  .work-carousel .swiper-button-next,
  .work-carousel .swiper-button-prev {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
  }

  .work-carousel .swiper-button-next {
    right: 15px;
  }

  .work-carousel .swiper-button-prev {
    left: 15px;
  }

  .work-carousel .content .cont h6 {
    letter-spacing: 2px;
  }

  .work-carousel .content .cont h4 {
    font-size: 20px;
  }

  .work-carousel .content .cont {
    width: 100%;
    text-align: center;
    left: 0;
    bottom: 20px;
  }

  .process .content ul li span {
    font-size: 40px;
    width: 60px;
  }

  .process .content {
    padding: 80px 25px;
  }

  .testimonials p {
    font-size: 16px;
  }

  .testimonials .quote-text .quote-icon {
    right: 30px;
    width: 40px;
  }

  .agency .img .icon {
    width: 70px;
    height: 70px;
    line-height: 90px;
  }

  .agency .img .icon svg {
    width: 60px;
  }

  .agency .img .exp h2 {
    font-size: 55px;
  }

  .extra-title,
  .agency .content h4,
  .min-area .content h4 {
    font-size: 22px;
  }

  .page-header .img-wrapper .title h3 {
    font-size: 40px;
  }

  .call-action .content h2,
  .call-action.next .content h2 {
    font-size: 38px;
  }

  .blog-pg.single .post .content .share-info {
    display: block;
  }

  .blog-pg.single .post .content .share-info .tags,
  .blog-pg.single .post .content .share-info .social {
    display: block;
    width: 100%;
    text-align: center;
  }

  .blog-pg.single .post .content .share-info .tags {
    margin-top: 20px;
  }

  .blog-pg.single .post .author {
    padding: 30px 15px;
  }

  .blog-pg.single .post .author .author-img {
    display: block;
    width: 120px;
    margin: 0 auto 30px;
  }

  .blog-pg.single .post .author .info {
    display: block;
    padding: 0;
    text-align: center;
  }

  .blog-pg.single .comments-area .item .comment-img {
    display: block;
    margin: 0 auto 30px;
    width: 60px;
  }

  .blog-pg.single .comments-area .item .info {
    display: block;
    padding: 0;
    text-align: center;
  }

  .blog-pg.single .comments-area .item.relped {
    margin-left: 0px;
  }

  .blog-pg.single .comments-area .item .info h6 {
    font-size: 14px;
    letter-spacing: 1px;
  }

  .blog-pg.single .comments-area .item .info .replay {
    top: -25px;
  }

  .portfolio.gutter .gallery .items {
    margin-top: 30px;
    padding: 0 15px;
  }

  .portfolio .filtering span {
    margin: 0 6px;
    font-size: 13px;
    line-height: 1.6;
  }

  .portfolio .filtering span:after {
    display: none;
  }
}

/* ====================== [ End Responsive ] ====================== */